<template>
  <div class="flex items-end pointer-events-none sm_items-start z-50" aria-live="assertive">
    <div class="w-full flex flex-col items-center space-y-4 sm_items-end">
      <transition
        enter-active-class="transition duration-300 ease-out transform"
        enter-class="translate-y-2 opacity-0 sm_translate-y-0 sm_translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm_translate-x-0"
        leave-active-class="transition duration-100 ease-in"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="isShowing"
          class="w-full flex items-start p-4 bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
          data-testid="v-notification-content"
          :class="{ focused, translucent }"
          :style="[{ 'background-color': variant === 'dark' ? '#222222' : '' }]"
        >
          <div class="flex-shrink-0">
            <slot v-if="!!type" name="icon">
              <XCircleIcon v-if="type === 'danger'" class="w-6 text-red-700" />
              <ExclamationTriangleIcon v-else-if="type === 'warning'" class="w-6 text-yellow-300" />
              <InformationCircleIcon v-else-if="type === 'info'" class="w-6 text-blue-700" />
              <CheckCircleIcon v-else class="w-6 text-copper-700" />
            </slot>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p v-if="$slots.title" class="text-sm font-medium text-gray-900 mb-0" :style="[{ color: variant === 'dark' ? '#ffffff' : '' }]">
              <slot name="title" />
            </p>
            <p v-if="$slots.default" class="mt-1 mb-0 text-sm text-gray-700"><slot /></p>
          </div>
          <div v-if="dismissible" class="ml-4 flex-shrink-0 flex">
            <slot name="button">
              <button
                class="bg-white rounded-md inline-flex text-gray-400 hover_text-gray-500 focus_outline-none focus_ring-2 focus_ring-offset-2 focus_ring-indigo-500"
                data-testid="v-notification-close"
                :style="[{ 'background-color': variant === 'dark' ? '#222222' : '' }]"
                @click="isShowing = false"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="w-6" />
              </button>
            </slot>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/vue/24/solid'

import { XMarkIcon } from '@heroicons/vue/24/solid'
import type { NotificationType } from '@/entities/notification'

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  dismissible: {
    type: [Boolean, String],
    default: true,
  },
  delay: {
    type: Number,
    default: 0,
  },
  focused: {
    type: [Boolean, String],
    default: false,
  },
  translucent: {
    type: [Boolean, String],
    default: false,
  },
  type: {
    type: String as PropType<NotificationType | null>,
    default: 'success',
  },
  variant: {
    type: String as PropType<'' | 'dark' | 'with-button'>,
    default: '',
  },
})

const emit = defineEmits(['dismissed'])

let timeoutId: NodeJS.Timeout | null = null
const isShowing = ref(props.show)

watch(isShowing, (showing: boolean) => {
  if (!showing) {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
    emit('dismissed')
  }
})

watch(
  toRef(props, 'show'),
  (show: boolean) => {
    isShowing.value = show

    if (!show || !props.delay) return

    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
    timeoutId = setTimeout(() => (isShowing.value = false), props.delay)
  },
  { immediate: true }
)
</script>

<style lang="stylus" scoped>
.translucent
  @apply bg-opacity-80 backdrop-blur-lg

.focused
  @apply px-2 py-2 rounded-2xl items-center
</style>
